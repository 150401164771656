import { type LiteClient, liteClient } from 'algoliasearch/lite';

export class AlgoliaClientKeyError extends Error {}

export default async function useAlgoliaClient(): Promise<LiteClient> {
  const { applicationId } = useRuntimeConfig().public.algolia;

  const algoliaKey = await useAlgoliaApiKey();

  if (!algoliaKey.value) {
    throw new AlgoliaClientKeyError('Algolia key is empty');
  }

  return liteClient(applicationId, algoliaKey.value);
}
