import { callWithNuxt } from '#app';
import { CACHE_TTL_MS_12_HOURS, CACHE_TTL_MS_15_MIN } from '~/lib/cache-utils';
import { getCategoriesWithTree } from '~/utils/api/catalog/categories';

export function useCategoryTree() {
  return useCachedAsyncData(
    'categories-v2',
    async (nuxtApp) =>
      nuxtApp === undefined ? await getCategoriesWithTree() : await callWithNuxt(nuxtApp, getCategoriesWithTree),
    {
      deep: false,
      serverMaxAge: CACHE_TTL_MS_12_HOURS,
      clientMaxAge: CACHE_TTL_MS_15_MIN,
    },
  );
}
