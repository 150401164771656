import type { ProductDeliveryTime, ProductState } from '~/utils/api/catalog/products/utils';

export const MarketPlaceSearchPanelAlgoliaIndex = 'marketplace_search_panel' as const;
export const DefaultMarketPlaceSearchAlgoliaIndex = 'marketplace_search_price_asc' as const;

export const MarketPlaceSearchIndexes = [
  DefaultMarketPlaceSearchAlgoliaIndex, // Asc price
  'marketplace_search_price_desc', // Desc price
  'marketplace_search_title_asc', // Alphabetical order
  'marketplace_search', // Relevance
] as const;

export interface MarketPlaceSearchPanelItem {
  id: string;
  objectID: string; // Needed for Algolia
  slug: string;
  title: string;
  image: string;
  minPrice: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _highlightResult: {
    title: {
      value: string;
    };
  };
  supplier: {
    id: string;
    name: string;
  };
  brand: string;
  categories: Record<`lvl${number}`, string[]>;
  mainCategory: string;
  hidden?: true;
}

export type MarketPlaceSearchItem = MarketPlaceSearchPanelItem & {
  attributes: Record<string, AttributeRawValue>;
  state: ProductState;
  deliveryTime: ProductDeliveryTime;
  frenchMade?: true;
};

export interface ApiFile {
  name: string;
  url: string;
  extensions: string;
  index: string;
  relativePath: string;
}

export type AttributeRawValue = string | boolean | number | string[] | ApiFile | Date | object | null;
