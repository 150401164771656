import type { CategoryCode, CategoryTreeItem, CategoryTreeItemApi } from '~/utils/api/catalog/categories/index';

const CATEGORY_CODE_SEPARATOR = '_';
const CATEGORY_SLUG_SEPARATOR = '-';

export const CATEGORIES_MAX_DEPTH = 2;

export function categoryCodeToSlug(code: CategoryCode): string {
  return code.replaceAll(CATEGORY_CODE_SEPARATOR, CATEGORY_SLUG_SEPARATOR);
}

export function categorySlugToCode(slug: string): CategoryCode {
  return slug.replaceAll(CATEGORY_SLUG_SEPARATOR, CATEGORY_CODE_SEPARATOR);
}

export function mapCategoryTreeItemApiToCategoryTreeItem(
  categoryTreeItemApi: CategoryTreeItemApi,
  callback?: (categoryTreeItem: CategoryTreeItem, categoryTreeItemApi: CategoryTreeItemApi) => void,
): CategoryTreeItem {
  const { code, label, nbProducts: productCount, children, parent } = categoryTreeItemApi;

  const categoryTreeItem: CategoryTreeItem = {
    code,
    label,
    slug: categoryCodeToSlug(code),
    productCount,
    children: children?.map((child) => mapCategoryTreeItemApiToCategoryTreeItem(child, callback)) ?? [],
    parentCode: parent?.at(-1),
  };

  callback?.(categoryTreeItem, categoryTreeItemApi);

  return categoryTreeItem;
}
