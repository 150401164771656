import { ApiCatalogError, getCatalogBaseUrl } from '~/utils/api/catalog';
import { mapCategoryTreeItemApiToCategoryTreeItem } from '~/utils/api/catalog/categories/utils';

export class ApiCatalogCategoryError extends ApiCatalogError {}

type CategoryCodeApi = string;

export interface CategoryTreeItemApi {
  code: CategoryCodeApi;
  label: string;
  description: string | null;
  image: string | null;
  imageRelativePath?: string | undefined;
  level: number;
  parent?: CategoryCodeApi[];
  nbProducts: number;
  hasProduct: boolean;
  children?: CategoryTreeItemApi[];
  directChildren?: string[];
  family?: string | null;
  familyRetrieveRule?: string | null;
}

export type CategoryCode = CategoryCodeApi;

export interface CategoryTreeItem {
  code: CategoryCode;
  slug: string;
  label: string;
  productCount: number;
  children: CategoryTreeItem[];
  parentCode?: CategoryCode;
}

export async function getCategoryTree(
  mapCallback?: Parameters<typeof mapCategoryTreeItemApiToCategoryTreeItem>[1],
): Promise<CategoryTreeItem[]> {
  try {
    const treeApi = await $fetch<CategoryTreeItemApi[]>('/category-tree', { baseURL: getCatalogBaseUrl() });

    return treeApi.map((node) => mapCategoryTreeItemApiToCategoryTreeItem(node, mapCallback));
  } catch (error) {
    throw new ApiCatalogCategoryError('Failed to get category tree', { cause: error });
  }
}

export interface CategoryTree {
  slug: string;
  children: CategoryTree[];
}

export interface CategoriesWithTree {
  dict: Record<string, { label: string; nbProducts: number; parents: string[]; directChildren: string[] }>;
  tree: CategoryTree[];
}

export async function getCategoriesWithTree(): Promise<CategoriesWithTree> {
  try {
    return await $fetch<CategoriesWithTree>('/categories-v2', { baseURL: getCatalogBaseUrl() });
  } catch (error) {
    throw new ApiCatalogCategoryError('Failed to get categories with tree', { cause: error });
  }
}

export type CategoryDetails = Omit<CategoryTreeItemApi, 'description'> & {
  descriptionTitle?: string;
  description?: string;
  bottomDescription?: string;
  seoTitle?: string;
  metaTitle?: string;
  metaDescription?: string;
};

export async function getCategoryDetails(categorySlug: string): Promise<CategoryDetails> {
  try {
    return await $fetch<CategoryDetails>(`/categories/${encodeURIComponent(categorySlug)}`, {
      baseURL: getCatalogBaseUrl(),
    });
  } catch (error) {
    throw new ApiCatalogCategoryError(`Failed to get category ${categorySlug}`, { cause: error });
  }
}
